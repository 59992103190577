import React, { useState } from "react";
import coversData from '../json/covers.json';
import { Link, useNavigate } from "react-router-dom";
import Partners from "./Partners";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const Covers = ({variant, tag}) => {
  const [selectedCover, setSelectedCover] = useState(null);
  const [openCoverModal, setOpenCoverModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const navigate = useNavigate();

  let coversFiltered = coversData.filter(cover => cover.door === variant);
  if (tag) {
    coversFiltered = coversFiltered.filter(cover => cover?.tags?.includes(tag));
  }
  const setCover = (newCover) => {
      setSelectedCover(newCover);
  };

  const handleOpenCoverModal = () => {
    setOpenCoverModal(true);
  };

  const handleCloseCoverModal = () => {
    setOpenCoverModal(false);
  };

  const handleOpenPreviewModal = () => {
    setOpenPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  const isMobile = window.innerWidth <= 768;
  const maxH3d = isMobile ? 'none' : '236vh';
  const maxHAI = isMobile ? 'none' : '180vh';
  const style3D = { maxHeight : maxH3d, flexDirection : 'row' };
  const styleAI = { maxHeight : maxHAI, flexDirection : 'column' };

  return (
    <div className="w-full">
      <div id="breadcrumbs">
        <Link className="link" to={`/`}>Home</Link>&nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
        {selectedCover === null ?
          <Link
            to={`/doorways/${variant}`}
            className="link"
            onClick={() => {
              setCover(null);
              navigate(`/doorways/${variant}`);
            }}
          >
            {variant.toUpperCase()}
          </Link>
          :
          <>
            <Link
              to={`/doorways/${variant}`}
              className="link"
              onClick={() => {
                setCover(null)
                navigate(`/doorways/${variant}`);
              }}
            >
              {variant.toUpperCase()}
            </Link>
            &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
            {selectedCover.title}
          </>
        }
      </div>
        {selectedCover === null ? (
        <>
          {coversFiltered?.length > 0 ?
            <p>Current {variant === '3d' ? '3D comic series' : 'AI sets'}:</p>
            :
            <p>No search results found.</p>
          }
        <div className={tag ? 'w-11/12 cover-container': 'w-11/12 masonry-flex'} style={variant === '3d' ? style3D : styleAI}>
          {coversFiltered.map(cover =>
            <div key={`key-cover-${cover.name}`} className="cover-images-div centered">
               <img onClick={(e) => setCover(cover)} key={`${cover.name}-img`} className="cover-images" src={`/covers/appallington_${cover.name}.jpg`} alt={cover.title} />
               <h2 className="text-xl">{cover.title}</h2>
            </div>
          )}
        </div>
        </>
      )
      :
      <>
        <table className="w-full">
          <tbody>
            <tr>
              <td className="w-1/4"><Button variant="contained" onClick={() => setCover(null)}>&lt;&lt; Back</Button></td>
              <td className="w-1/2"><h2 className="text-2xl">{selectedCover.title}</h2></td>
              <td className="w-1/4">&nbsp;</td>
            </tr>
          </tbody>
        </table>
        <p className="p-5 bg-gray-600 ml-auto mr-auto mt-4 mb-4 cover-description">{selectedCover.desc}</p>
        <p className="text-sm">(Click the large image {selectedCover.preview ? 'or the preview below it ' : ''}to enlarge)</p>
        <img className="cover-images-large" onClick={handleOpenCoverModal} src={`/covers/appallington_${selectedCover.name}.jpg`} alt={selectedCover.title} />
        {selectedCover.preview && (
          <img onClick={() => handleOpenPreviewModal()} className="preview-image" src={`/previews/preview_${selectedCover.name}.jpg`} alt={`Preview for ${selectedCover.title}`} />
        )}
        <p>Tags:<br />|&nbsp;&nbsp;
          {selectedCover?.tags.sort().map(tag => <>
            <Link
              key={`key-tag-${tag.replace(/ /g, '_')}`}
              to={`/tag/${tag.replace(/ /g, '_')}`}
              className="link"
              onClick={(e) => {
                  setCover(null);
                navigate(`/tag/${tag.replace(/ /g, '_')}`);
                }
              }
            >
              {tag}
            </Link>&nbsp;&nbsp;|&nbsp;&nbsp;
          </>)}
        </p>
        {selectedCover.saleReady ?
          <>
            <h2><br />Available for sale from:</h2>
            <Partners saleType={variant} />
          </>
          :
          <p><br />(Coming soon!)</p>
        }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openCoverModal}
        onClose={() => handleCloseCoverModal()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openCoverModal}>
          <Box className="cover-modal">
            <img className="cover-images-modal" onClick={() => handleCloseCoverModal()} src={`/covers/appallington_${selectedCover.name}.jpg`} alt={selectedCover.title} />
          </Box>
        </Fade>
      </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openPreviewModal}
          onClose={() => handleClosePreviewModal()}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openPreviewModal}>
            <Box className="preview-modal">
              <img className="cover-images-modal" onClick={() => handleClosePreviewModal()} src={`/previews/preview_${selectedCover.name}.jpg`} alt={selectedCover.title} />
            </Box>
          </Fade>
        </Modal>
      </>
     }
    </div>
  );
}

export default Covers;
