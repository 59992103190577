import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import Error404 from './pages/Error404';
import App from './App';
import Doors from './pages/Doors';
import Tags from './pages/Tags';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <Error404 />,
    element: <App />,
  },
  {
    path: "doorways/:doorId",
    errorElement: <Error404 />,
    element: <Doors />,
  },
  {
    path: "tag/:tagId",
    errorElement: <Error404 />,
    element: <Tags />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
