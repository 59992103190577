import React from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../icons/artuntamed.png';
import icon2 from '../icons/bluesky.png';

const Footer = () => {
  const theYear = new Date().getFullYear();
  return (
    <div id="footer" className="text-sm">
      <div className="social-icons-div">
        <Link className="link" to={`https://artuntamed.com/index.php?media/users/appallington.10536/`}>
          <img src={icon1} className="social-icons" alt="Appallington on Art Untamed" />
        </Link>
        <Link className="link" to={`https://bsky.app/profile/appallington.bsky.social`}>
          <img src={icon2} className="social-icons" alt="Appallington on Bluesky" />
        </Link>
      </div>
      <p className="mt-4">All content copyright &copy; 2022-{theYear} by Appallington.</p>
      <p>These images are intended for adult use only. Please abide by the laws of your country. All scenes presented illustrate consensual activity. All rendered characters are 18 years old or older. This is a work of fiction. Names, characters, places and incidents either are products of the author’s imagination or are used fictitiously. Any resemblance to actual events or locales or persons, living or dead, is entirely coincidental.</p>
    </div>
  );
}

export default Footer;
