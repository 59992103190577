import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import coversData from '../json/covers.json';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Covers from '../components/Covers';

const Tags = () => {
  const [allTags, setAllTags] = useState([]);
  const location = useLocation();
  const url = location.pathname;
  const tag = url.substring(url.lastIndexOf('tag/') + 4, url.length).replace(/_/g, ' ');
  const coversFiltered = coversData.filter(cover => cover?.tags?.includes(tag));
  const noTags = coversFiltered.length === 0;

  useEffect(() => {
    const newTags = [];
    coversData.map(cover => {
      cover?.tags.forEach(tag => {
        if (!newTags.includes(tag)) {
          newTags.push(tag);
        }
      })
    })
    newTags.sort();
    setAllTags(newTags);
    // {allTags.map(tag => <>&lt;url&gt;<br />&lt;loc&gt;https://appallington.com/tag/{tag.replace(/ /g, '_')}&lt;/loc&gt;<br />&lt;/url&gt;<br /></>)}
  }, []);

  return (
    <div className="page-content">
      <Header />
      <h1 id="page-title">3D Comics - Tag: {noTags ? "not found" : tag}</h1>
      <div className="page-top">
        <Covers variant={'3d'} tag={tag} />
      </div>
      <h1 id="page-title">AI Photos - Tag: {noTags ? "not found" : tag}</h1>
      <div className="page-top">
        <Covers variant={'ai'} tag={tag} />
      </div>
      <Footer />
    </div>
  );
}

export default Tags;
