import React from 'react';
import {Link} from "react-router-dom";

const Partners = ({saleType}) => {
  return (
    <div>
      {(saleType === '3d' || saleType === 'all') && (
        <>
        <Link className="block p-8 link text-xl" to={`https://subscribestar.adult/appallington-adult-comics`}>&#128520; Appallington 3D Comics on SubscribeStar</Link>
        <Link className="block p-8 link text-xl" to={`https://3dperversion.com/porn-comics-artist/Appallington%20Adult%20Comics`}>&#128520; Appallington 3D Comics on 3D Perversion</Link>
        </>
      )}
      {(saleType === 'ai' || saleType === 'all') && (
        <Link className="block p-8 link text-xl" to={`https://www.patreon.com/AIBondageBDSM`}>&#128520; Appallington AI Bondage on Patreon</Link>
      )}
  </div>
  );
}

export default Partners;
