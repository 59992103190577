import React from 'react';
import Header from '../components/Header';
const Error404 = () => {
    return (
      <div className="page-content">
        <Header />
        <h1 style={{ fontSize: '1500%' }} >404<br />NOT FOUND</h1>
      </div>
    );
}

export default Error404;
