import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import logo from './logo.png';
import enter1 from './enter-3d-mall6.jpg';
import enter2 from './enter-ai-basement7.jpg';
import './App.css';
import Partners from './components/Partners';
import Footer from './components/Footer';
const App = () => {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    localStorage.setItem('agreed', true);
    setOpen(false);
  }
  const handleExit = () => document.location = 'https://duckduckgo.com'

  useEffect(() => {
    // localStorage.clear('agreed');
    const agreedFlag = localStorage.getItem('agreed');
    if (agreedFlag) {
      handleClose();
    }
  }, []);

  return (
    <>
    <div className="App justify-center">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Appallington logo" />
        <p style={{ marginTop: '-32px', marginBottom: '32px' }}>
          Fantasies. Rendered.
        </p>
        <p style={{ marginTop: '-16px', marginBottom: '16px' }}><strong>This is the official and ONLY authorized site for Appallington.</strong></p>
        <p className="text-sm mb-1">&#129397; BANNED from DeviantArt!! ("Fetish content!")</p>
        <p className="text-sm mb-1">&#128545; BANNED from Reddit!! (False report)</p>
        <p className="text-sm mb-1">&#128561; BANNED from Gumroad!! ("Too risky for payment processor!")</p>
        <p className="text-sm mb-1">&#128580; BANNED by Patreon ("Incest!!" - Twin sisters)</p>
        <div className="flex-container mt-8">
          <div className="flex-child">
            <Link to={`doorways/3d`}>
              <div className="centered link">3D Comics</div>
              <img className="enter-images" src={enter1} alt="Enter 3D Comics" />
            </Link>
          </div>
          <div className="flex-child">
            <Link to={`doorways/ai`}>
              <div className="centered link">AI Photos</div>
              <img className="enter-images" src={enter2} alt="Enter AI Photos" />
            </Link>
          </div>
        </div>
      </header>
      <h2>Buy stuff from these authorized vendors:</h2>
      <Partners saleType={"all"} />
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
      >
        <Fade in={open}>
          <Box className="enter-modal">
            <h1 className="text-xl">
              This is an adult website
            </h1>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              This website contains age-restricted materials including nudity and explicit depictions of sexual activity. By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you are accessing the website from and you consent to viewing sexually explicit content.
            </Typography>
            <div className="mt-8">
              <div className="modal-button">
                <Button style={{ marginRight: '8px' }} variant="contained" onClick={handleClose}>I am 18 or older - Enter</Button>
              </div>
              <div className="modal-button">
                <Button style={{ marginLeft: '8px' }} variant="outlined" onClick={handleExit}>I am under 18 - Exit</Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  <Footer />
  </>
  );
};

export default App;
