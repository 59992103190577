import React from 'react';
import { Link } from "react-router-dom";
import logo from '../logo.png';
import Button from '@mui/material/Button';

const Header = () => {
  const showSignin = false;
  return (
    <div id="header">
      <Link to={`/`}>
        <img src={logo} id="header-logo" alt="Appallington logo" />
      </Link>
      {showSignin ?
        <div id="sign-in">
          <Link to={`/signin`}>
            <Button style={{ margin: '8px' }} variant="contained" size="small">
              Sign In
            </Button>
          </Link>
          <Link to={`/signup`}>
            <Button style={{ margin: '8px' }} variant="outlined" size="small">
              Sign Up
            </Button>
          </Link>
        </div>
        :
        <div id="sign-in" />
      }
    </div>
  );
};

export default Header;
