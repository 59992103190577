import React from 'react';
import Header from '../components/Header';
import Covers from '../components/Covers';
import Footer from '../components/Footer';

const Doors = () => {
  const url = window.location.toString().toLowerCase();
  const door = url.substring(url.lastIndexOf('/') + 1, url.length);

  if ((door !== 'ai') && (door !== '3d')) {
    document.location = '/';
  }

  return (
    <div className="page-content">
      <Header />
        {door === '3d' && (
            <>
          <h1 id="page-title">3D Comics</h1>
            </>
        )}
        {door === 'ai' && (
          <h1 id="page-title">AI Photos</h1>
        )}
        <div className="page-top">
          <Covers variant={door} />
        </div>
      <Footer />
    </div>
  );
}

export default Doors;
